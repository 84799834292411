import React from "react";
import NavBar from "./NavBar";
import ContactBar from "./ContactBar";
import { Banner } from "../../Utils/Constant";

const Home = () => {
  return (
    <div>
      <ContactBar />
      <NavBar />
      <img src={Banner} alt="banner" />
    </div>
  );
};

export default Home;
