import React from "react";
import { Icon } from "semantic-ui-react";

const ContactBar = () => {
  return (
    <div className="flex flex-wrap bg-blue-950 text-white justify-around p-1">
      <div className="flex flex-wrap font-extralight text-md pr-48">
        <ul className="flex flex-wrap p-1 m-1 text-md font-bold justify-between ">
          <Icon name="call" className="text-yellow-400 rotate-90 " />
          <li className="px-1 pr-2 mx-1 hover:underline">
            91989-88884 / 91187-16840
          </li>
          <Icon
            name="mail outline"
            className="text-yellow-400 "
            style={{ background: "none", outline: "none" }}
          />
          <li className="px-1 mx-1 hover:underline ">
            pacific.edu.consultants@gmail.com
          </li>
        </ul>
      </div>
      <div className="pl-2 ml-2 flex items-center">
        <Icon name="facebook f" className="ml-2 pr-2 hover:cursor-pointer" />
        <Icon name="twitter" className="ml-2 pr-2 hover:cursor-pointer" />
        <Icon name="linkedin" className="ml-2 pr-2 hover:cursor-pointer" />
        <Icon name="instagram" className="ml-2 pr-2 hover:cursor-pointer" />
      </div>
    </div>
  );
};

export default ContactBar;
