// // index.js
// import React from "react";
// import ReactDOM from "react-dom/client";
// import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import Home from "./Component/Home";
// import Error from "./Component/Error";
// import "./App.css";
// import About from "./Component/About";
// import Contact from "./Component/Contact";

// const AppLoader = createBrowserRouter([
//   {
//     path: "/",
//     element: <Home />,
//     children: [
//       { path: "/about", element: <About /> },
//       { path: "/contact", element: <Contact /> },
//     ],
//     errorElement: <Error />,
//   },
// ]);

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<RouterProvider router={AppLoader} />);

// App.js
import React from "react";
import { Outlet } from "react-router-dom";
import "./index.css"; // Make sure to import your CSS file
import "semantic-ui-css/semantic.min.css";

const App = () => {
  return (
    <div className="App">
      <Outlet />
    </div>
  );
};

export default App;
