import React from "react";
import smallIcon from "../../Assets/pacific-logo-small.png";
import { Icon } from "semantic-ui-react";

const NavBar = () => {
  return (
    <div className="flex flex-wrap p-2 m-2 bg-white text-black justify-around ">
      <img alt="company_icon" className="w-50" src={smallIcon} />
      <ul className="flex flex-wrap p-4 m-4 bg-white text-blue-950 text-md font-bold justify-between ">
        <li className="pl-2 ml-2 hover:text-amber-400 cursor-pointer">HOME</li>
        <li className="pl-2 ml-2 hover:text-amber-400 cursor-pointer">
          ABOUT <Icon name="angle down" />
        </li>
        <li className="pl-2 ml-2 hover:text-amber-400 cursor-pointer">
          MBBS ABROAD <Icon name="angle down" />
        </li>
        <li className="pl-2 ml-2 hover:text-amber-400 cursor-pointer">
          FEES <Icon name="angle down" />
        </li>
        <li className="pl-2 ml-2 hover:text-amber-400 cursor-pointer">
          UNIVERSITIES <Icon name="angle down" />
        </li>
        <li className="pl-2 ml-2 hover:text-amber-400 cursor-pointer">
          INFO <Icon name="angle down" />
        </li>
        <li className="pl-2 ml-2 hover:text-amber-400 cursor-pointer">
          CONTACT US
        </li>
      </ul>
    </div>
  );
};

export default NavBar;
